/* eslint-disable @typescript-eslint/prefer-for-of */
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  signal,
  ViewChild,
} from '@angular/core';
import moment from 'moment-timezone';
import { Observable, Subscription } from 'rxjs';
import { CONSTANT, DB, DINETYPE, PRINT, TABLE_STATUS } from '../../constant';
import { KioskActions, KioskSelectors } from 'src/app/state/kiosk';
import { KioskOrderService } from 'src/app/services/kiosk-order-service';
import { ConfirmationService, MessageService } from 'primeng/api';
import * as _ from 'lodash';
import { Actions, Subject } from 'src/app/auth/rules';
import { Store } from '@ngrx/store';
import { KioskStoreService } from 'src/app/services/kiosk.store.service';
import {
  Account,
  IAdditionalDineType,
  ICategoryData,
  IOrderPageLayout,
  IPayByCashCartParam,
  IPrinterStatus,
  IProductData,
  IReceiptTemplate,
  IServerSalesReportResponse,
  KioskSettings,
  KioskStore,
  PosData,
} from 'src/app/interface/maestro-interface';
import { AppState } from 'src/app/state/app.state';
import {
  getMaestroInfoData,
  getProductPricing,
  getUserDetailsShiftProgress,
  getUserShiftProgressStatus,
  tables,
} from 'src/app/state/kiosk/kiosk.selector';
import { KioskAnalyticsService } from 'src/app/services/kiosk.analytics.service';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { TableModule } from 'primeng/table';
import { CartCardComponent } from '../cart-card/cart-card.component';
import { PaymentGatewayComponent } from '../payment-gateway/payment-gateway.component';
import { Ability, PureAbility } from '@casl/ability';
import { DialogModule } from 'primeng/dialog';
import { SelectButtonModule } from 'primeng/selectbutton';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchBoxComponent } from '../search-box/search-box.component';
import { TooltipModule } from 'primeng/tooltip';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ButtonModule } from 'primeng/button';
import { AccordionModule } from 'primeng/accordion';
import { SplitterModule } from 'primeng/splitter';
import { PaginatorModule, PaginatorState } from 'primeng/paginator';
import { ToastModule } from 'primeng/toast';
import { LoaderComponent } from '../loader/loader.component';
import { OrderCardComponent } from '../order-card-component/order-card-component.component';
import { SokPrintModalComponent } from '../sok-print-modal-component/sok-print-modal-component.component';
import { ProductSearchPipe } from '../pipe/product-search.pipe';
import pk from 'package.json';
import {
  TranslateModule,
  TranslatePipe,
  TranslateService,
} from '@ngx-translate/core';
import { RxdbSyncService } from 'src/sync/rxdb.sync.service';
import { DateFormatterService } from '../../services/dateformat';
import { CalendarModule } from 'primeng/calendar';
import { InputTextModule } from 'primeng/inputtext';
import { AppComponent } from 'src/app/app.component';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TagModule } from 'primeng/tag';
import { BadgeModule } from 'primeng/badge';
import { accountActions, accountSelectors } from 'src/app/state/account';
import { AutoFocusModule } from 'primeng/autofocus';
import { AbilityModule, AblePipe } from '@casl/angular';
import { CardModule } from 'primeng/card';
import { LogArgs } from 'src/app/decorators/LogArgs';
import { InputSwitchModule } from 'primeng/inputswitch';
import { PrintReceiptService } from 'src/app/services/print-receipt.service';
import { PinKeyboardComponent } from '../pin-keyboard/pin-keyboard.component';
import { MessagesModule } from 'primeng/messages';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { LangUtilService } from 'src/app/services/lang.util.service';
import { FieldsetModule } from 'primeng/fieldset';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
const KIOSK_STORE_LOCATION = CONSTANT.KIOSK.STORE_lOCATION;

const imports = [
  AutoFocusModule,
  CardModule,
  InputSwitchModule,
  DividerModule,
  TranslateModule,
  ToggleButtonModule,
  ProductSearchPipe,
  DialogModule,
  PaymentGatewayComponent,
  OrderCardComponent,
  SokPrintModalComponent,
  LoaderComponent,
  ToastModule,
  PaginatorModule,
  CartCardComponent,
  SplitterModule,
  SelectButtonModule,
  CommonModule,
  FormsModule,
  TooltipModule,
  SearchBoxComponent,
  OverlayPanelModule,
  ButtonModule,
  ConfirmPopupModule,
  TableModule,
  AccordionModule,
  CalendarModule,
  InputTextModule,
  ReactiveFormsModule,
  TagModule,
  BadgeModule,
  AbilityModule,
  PinKeyboardComponent,
  MessagesModule,
  DropdownModule,
  FieldsetModule,
  ConfirmDialogModule,
];

@Component({
  selector: 'app-maestro-page',
  standalone: true,
  imports: imports,
  providers: [
    MessageService,
    ConfirmationService,
    AblePipe,
    {
      provide: PureAbility,
      useExisting: Ability,
    },
  ],
  templateUrl: './maestro-page.component.html',
  styleUrl: './maestro-page.component.scss',
})
export class MaestroPageComponent implements OnInit, OnDestroy {
  @ViewChild(PinKeyboardComponent) pinKeyboardComp: PinKeyboardComponent;
  reportGenFromServer = signal(false);
  // outOfStockMsg: string;
  versionNumber = pk.version;
  cartOrderIdTotalTax: number | string = 0;
  reloadAgainState: boolean;
  salesModalTableData: any[];
  dineTypeSelectBtn: any[] = [];
  viewOption: any[] = [
    { label: 'Rich', value: 'rich' },
    { label: 'Simple', value: 'simple' },
  ];
  orderPageLayout: IOrderPageLayout = undefined;
  layoutt = '';
  panelSize: number[];
  SelectBtnValue = '';
  selectedUiConfig = {
    view: 'rich',
    cartPosition: '',
    catPosition: '',
  };
  cols = [
    { field: 'item', header: 'Item' },
    { field: 'quantity', header: 'Qty' },
    // { field: 'price', header: 'Price' },
    { field: 'amount', header: 'Amount' },
    { field: 'remove', header: 'Remove' },
  ];
  // showOutOfStock = false;
  // private searchTextSub: Subscription;
  showLoader: boolean;
  kioskSettings: KioskSettings = null;
  storelist: KioskStore[] = undefined;
  productSubscription: Subscription[] = [];
  displayPayementStatusModal = {
    visibility: false,
    orderId: '0',
    id: null,
    amt: 0,
    accountId: null,
  };

  showAddOrdersModal = true;
  showWallpaper = true;
  current_branch: any;

  allAvailableCategories$: Observable<ICategoryData[]> = this.store.select(
    KioskSelectors.getAvailableCategories
  );
  allAvailableProducts$: Observable<IProductData[]> = this.store.select(
    KioskSelectors.getAvailableProducts
  );
  sentKitchendData$ = this.store.select(KioskSelectors.getKitchenSentData);
  maestroAreaData: any;
  currentFloor: string;
  currentTableId: string;
  defaultFloorId: string;

  cartItems$: Observable<IProductData[]> = this.store.select(
    KioskSelectors.getCartItems
  );
  cartLength$: Observable<number> = this.store.select(
    KioskSelectors.getCartLength
  );
  printerStatus: IPrinterStatus;
  getIsVersionUpdated$ = this.store.select(
    accountSelectors.getIsVersionUpdated
  );
  private quantityStatusSubscription$: Subscription;
  private cartItemsSubscription$: Subscription;
  searchedProduct = '';
  categoryIdStr: any = undefined;
  groupIdStr: any = undefined;
  cartOrderId: any = undefined;
  selectedCartItem: any;
  showQrScanner = {
    visibility: false,
    src: '',
  };
  checkPaymentStatusInterval: any;
  paymentIsSuccessfull = false;
  showTaxDetails = false;
  selectedCategoryIdx = -1;
  cartTax: any = [];
  showCashModal = false;
  tableCartItems: any[] = [];
  currentOrderPaid = false;
  occupiedTableData: any;
  showTableModal = false;
  showTaxBreakup = false;
  showEditOrderOption = false;
  selectedTableData: any;
  showSelectedTableData: any;
  showtableData = false;
  selectedCategoryData: ICategoryData;
  TaxBreakupDetails: any;
  showCardModal = false;
  placeholderValue = '';
  isReloadModalVisible = false;
  subOrderData: any[];
  currentTableOrderDocId: string;
  selectedOrderTypeValue = '';
  showWarningBorder = false;
  ACTIONS = Actions;
  SUBJECT = Subject;
  salesModal = {
    header: '',
    displayModal: false,
    showShiftListModal: false,
    password: null,
    displayConfirmModal: false,
    tabledata: [],
    pagination: {
      currentPage: 1,
      totalNoOfRecords: 0,
      recordsPerPage: 20,
      pageCountValue: 0,
      index: 0,
      pageNumber: 1,
      noOfPagesList: 1,
    },
    tableApiData: {},
    maestroStoreId: null,
    appLoader: false,
    salesOptions: [
      {
        label: 'Shift Base',
        value: 'shiftBase',
      },
      {
        label: 'Time Base',
        value: 'timeBase',
      },
    ],
    selectedSalesOption: 'shiftBase',
    shiftTimeRange: undefined,
  };
  maxSalesDetailsDate: Date;
  minSalesDetailsDate: Date;
  startRangeDate: Date;
  endRangeDate: Date;
  selectedDataRange = {
    start: '',
    end: '',
  };
  shift = {
    isStarted: false,
    startTimeFormatted: '',
  };
  showDbUrlEditDialog = false;
  syncdb = {
    isLocalNetwork: false,
    url: '',
    userid: '',
    password: '',
  };
  isSwitchTable = false;
  activeIndex: number[] = [];
  maestroInfoData: PosData;
  additionalDineTypes = [];
  timeOut = null;
  menuVisible: boolean;
  openSearchBar: boolean;
  isAvailableTableNotSelected = true;
  userShiftData$ = this.store.select(KioskSelectors.getUserShiftList);
  userDetails$ = this.store.select(accountSelectors.getUserProfileDetails);
  switchToTable = null;
  isInternetAvailable = true;
  editInKitchenItems = [];
  isInKitchenItems: boolean;
  changedInKitchenItems = {
    products: {
      remove: [],
    },
    tableId: '',
    orderDocId: '',
    subOrderId: '',
  };
  selectedInKitchenProdcut: any;
  isKitchenEmpty = true;
  popUpKey: string;
  syncing$ = this.store.select(accountSelectors.syncing);
  showAddInstructuinDialog = false;
  currentAddOrderInstructionObj: any;
  chips: {
    label: string;
    selected: boolean;
  }[];
  userShiftProgress = '';
  selectedLang: any;
  lang = [
    { name: 'english', code: 'en' },
    { name: 'kannada', code: 'kn' },
  ];
  translatePipe: any;
  productPricing$ = this.store.select(getProductPricing);
  receiptTemplate: IReceiptTemplate;
  printerStatusData = {
    ipAddress: '',
    error: '',
  };
  offlinePrinters = '';
  hasAddAccessAfterReceiptGeneration = signal<boolean>(false);
  constructor(
    private messageService: MessageService,
    public dateFormatter: DateFormatterService,
    private store: Store<AppState>,
    private kioskService: KioskStoreService,
    private kioskOrderService: KioskOrderService,
    private analyticsService: KioskAnalyticsService,
    private confirmationService: ConfirmationService,
    private rxdb: RxdbSyncService,
    public app: AppComponent,
    protected ability: Ability,
    private printer: PrintReceiptService,
    private kioskStoreService: KioskStoreService,
    private langUtilService: LangUtilService,
    private translate: TranslateService,
    private cd: ChangeDetectorRef
  ) {
    this.setPlaceholderForSearch();
  }

  @LogArgs()
  ngOnInit(): void {
    this.showLoader = true;
    this.dateFormatter.getTimezone().then((r) => r);

    const acc: Account = JSON.parse(localStorage.getItem('account'));
    let dbName = `db_${acc._id}`;
    const storeId = localStorage.getItem('storeId');
    if (storeId && storeId !== 'undefined' && acc?._id) {
      dbName = `${dbName}_${storeId}`;
      this.rxdb.createRxDB(dbName).then(() => {
        this.rxdb.updateTelemetry().then(console.log).catch(console.error);

        this.kioskOrderService.getTax();
        this.store.dispatch(KioskActions.getMaestroInfo());
        this.store.dispatch(KioskActions.loadBranchList());
        this.kioskStoreService.getOperators().subscribe({
          next: (r) => {
            try {
              const dbpos = localStorage.getItem(DB.POS_OPERATORS);
              if (r && dbpos && dbpos !== 'undefined') {
                // this.messageService.add({
                //   severity: 'success',
                //   summary: `Offline Login Enabled`,
                //   sticky: true,
                //   key: 'kiosk-order-page',
                // })
              }
            } catch (e) {
              this.messageService.add({
                severity: 'error',
                summary: `Offline Login Failed`,
                sticky: true,
                key: 'kiosk-order-page',
              });
            }
          },
        });
        this.productSubscription.push(
          this.store
            .select(accountSelectors.getIsInternetAvailable)
            .subscribe((res) => {
              this.isInternetAvailable = res;
            })
        );
        this.productSubscription.push(
          this.store
            .select(getUserShiftProgressStatus)
            .subscribe((status) => (this.shift.isStarted = status))
        );
        this.productSubscription.push(
          this.store
            .select(getUserDetailsShiftProgress)
            .subscribe((status: string) => {
              if (status) {
                this.userShiftProgress = status;
                this.updateInProgreesShitTime(this.userShiftProgress);
              }
            })
        );

        this.productSubscription.push(
          this.store.select(getMaestroInfoData).subscribe((d) => {
            const obj = {
              name: d?.name,
              noofFloors: d?.noofFloors,
              _id: d?._id,
            };
            this.current_branch = obj;
            if (this.current_branch) {
              this.store.dispatch(
                KioskActions.currentBranch({
                  currentBranch: this.current_branch,
                })
              );
              localStorage.setItem(
                KIOSK_STORE_LOCATION,
                JSON.stringify(this.current_branch)
              );
            }
            this.maestroInfoData = d;
            if (d?._id) {
              this.salesModal.maestroStoreId = d?._id;
            }
            if (d?.posData?.area) {
              this.defaultFloorId = d?.posData?.area;
              this.currentFloor = this.defaultFloorId;
            }
            if (d?.posData?.counterPrinting?.enabled) {
              this.store.dispatch(KioskActions.loadCounterData());
            }
          })
        );
        this.productSubscription.push(
          this.sentKitchendData$.subscribe((k) => {
            this.isAnyItemInKitchen(k);
          })
        );
        this.productSubscription.push(
          this.store
            .select(KioskSelectors.getKioskSettings)
            .subscribe((settings: KioskSettings) => {
              if (settings) {
                this.kioskSettings = settings;
                this.syncdb = { ...settings?.syncdb };
                if (localStorage.getItem('kiosk-ui-view') !== null) {
                  this.selectedUiConfig = JSON.parse(
                    localStorage.getItem('kiosk-ui-view')
                  );
                } else {
                  this.selectedUiConfig.catPosition =
                    this.kioskSettings?.posUISettings?.layout?.category ||
                    'catBottom';
                  this.selectedUiConfig.cartPosition =
                    this.kioskSettings?.posUISettings?.layout?.cart ||
                    'cartLeft';
                  localStorage.setItem(
                    'kiosk-ui-view',
                    JSON.stringify(this.selectedUiConfig)
                  );
                }
                this.chips = this.kioskSettings?.instructions?.values?.map(
                  (label) => ({ label, selected: false })
                );
                if (this.kioskSettings?.posUISettings?.dineType?.dineIn) {
                  const item = {
                    label: 'app.pos.dineIn',
                    value: DINETYPE.DINE_IN,
                    disabled: false,
                  };
                  const isValueExist = this.dineTypeSelectBtn.some(
                    (option) => option.value === item.value
                  );
                  if (!isValueExist) {
                    this.dineTypeSelectBtn.push(item);
                  }
                }
                if (
                  this.kioskSettings?.posUISettings?.dineType?.dineOut &&
                  this.dineTypeSelectBtn?.length < 3
                ) {
                  const item = {
                    label: 'app.pos.dineOut',
                    value: DINETYPE.DINE_OUT,
                    disabled: false,
                  };
                  const isValueExist = this.dineTypeSelectBtn.some(
                    (option) => option?.value === item?.value
                  );
                  if (!isValueExist) {
                    this.dineTypeSelectBtn.push(item);
                  }
                }
                if (this.kioskSettings?.posUISettings?.dineType?.table) {
                  const item = {
                    label: 'app.pos.table',
                    value: DINETYPE.TABLE,
                    disabled: false,
                  };
                  const isValueExist = this.dineTypeSelectBtn.some(
                    (option) => option?.value === item?.value
                  );
                  if (!isValueExist) {
                    this.dineTypeSelectBtn.push(item);
                  }
                }
                this.getAdditionalDineServices();
                if (this.selectedUiConfig.catPosition === 'catLeft') {
                  this.layoutt = 'horizontal';
                  this.panelSize = [25, 80];
                } else {
                  this.layoutt = 'vertical';
                  this.panelSize = [80, 25];
                }
                this.orderPageLayout = this.kioskSettings?.orderPageLayout;
              }
              if (!settings?.orderPageLayout) {
                this.store.dispatch(KioskActions.getLayoutKioskOrderPage());
              }
            })
        );
        this.showTaxDetails = false;
        this.resetCartValues();
        this.productSubscription.push(
          this.store
            .select(KioskSelectors.getBranchList)
            .subscribe(async (branches) => {
              if (branches?.length > 0) {
                // this.loading = false;
                this.storelist = branches;
                // this.current_branch = this.storelist[0];
                // localStorage.setItem(KIOSK_STORE_LOCATION, JSON.stringify(this.storelist[0]));
                // this.store.dispatch(KioskActions.currentBranch({currentBranch: this.current_branch}));
                this.store.dispatch(KioskActions.checkIfUserShiftInProgress());
                this.store.dispatch(KioskActions.getCategories());
                this.store.dispatch(KioskActions.getMaestroAreas());
              }
            })
        );

        this.store.dispatch(KioskActions.getLayoutKioskOrderPage());
        this.productSubscription.push(
          this.store.select(KioskSelectors.getMaestroAreas).subscribe((v) => {
            if (v) {
              this.maestroAreaData = v;
              if (!this.defaultFloorId) {
                this.defaultFloorId = v[0]?._id;
                this.currentFloor = this.defaultFloorId;
              }
              if (this.currentFloor) {
                this.getTablesWithFloorId();
              }
            }
          })
        );
        this.productSubscription.push(
          this.store
            .select(KioskSelectors.getReceiptTemplateSelector)
            .subscribe((res) => {
              this.receiptTemplate = res;
            })
        );
        this.productSubscription.push(
          this.store.select(KioskSelectors.printerStatus).subscribe((res) => {
            if (res && res.status) {
              const offlinePrinters = Object.keys(res.status).filter(
                (key) => !res.status[key].isConnected
              );
              this.offlinePrinters = offlinePrinters.toString();
            }
            this.printerStatus = res;
          })
        );
        this.showLoader = false;
      });
    } else {
      this.store.dispatch(
        accountActions.syncing({
          isSyncing: true,
          msg: 'Error: Store Id not found',
          error: new Error('Store Id not found'),
          url: 'DB Init Failed',
        })
      );
    }

    console.log('dbName', dbName);
    if (this.ability.can(Actions.ALLOW_AFTER_PRINT_RECEIPT, Subject.POS_MANAGEMENT)) {
      this.hasAddAccessAfterReceiptGeneration.set(true);
    }
    this.productSubscription.push(
      this.store.select(tables).subscribe({
        next: (res) => {
          this.showLoader = false;
          if (this.isSwitchTable) {
            this.switchToTable = null;
          }
          this.occupiedTableData = this.assignUpdatedTime(
            structuredClone(res?.['data'])
          );
          if (res?.['remote']) {
            if (this.selectedTableData) {
              const t = this.occupiedTableData.find(
                (t) => t._id === this.selectedTableData._id
              );
              if (
                t?.aProperties?.status ===
                this.selectedTableData?.aProperties?.status
              ) {
                if (
                  !t?.aProperties?.orderData &&
                  t?._id != this.showSelectedTableData?._id
                ) {
                  this.currentOrderPaid = true;
                  this.showtableData = false;
                  this.showSelectedTableData = {};
                  this.isInKitchenItems = true;
                  this.isAvailableTableNotSelected = true;
                  this.cartOrderId = undefined;
                  this.currentTableOrderDocId = '';
                  this.currentTableId = '';
                  this.subOrderData = [];
                  if (
                    t?.aProperties?.hasOwnProperty('orderData') &&
                    t?.tableNo
                  ) {
                    this.handleAvailableDineTypes();
                  }
                  this.tableCartItems = [];
                  this.resetCartValues();
                  return;
                } else {
                }
                if (
                  !_.isEqual(
                    this.subOrderData,
                    t?.aProperties?.orderData?.subOrderData
                  )
                ) {
                  this.store.dispatch(
                    KioskActions.setSwitchTable({ isswitchTable: false })
                  );
                  this.isSwitchTable = false;
                  // this.tableCartItems = [];
                  this.subOrderData = [];
                  this.selectedTableData = t;
                  this.showSelectedTableData = t;
                  this.currentTableOrderDocId = t?.aProperties?.orderDocId;
                  this.currentTableId = t?._id;
                  this.selectedTableData['status'] =
                    t?.aProperties?.status ?? 'available';
                  if (this.selectedTableData['status'] !== 'available') {
                    this.editOrBookOrder(this.selectedTableData);
                  }
                  if (this.isInKitchenItems) {
                    this.cancelKitchenItemsMod();
                  }
                }
              } else {
                if (t) {
                  this.isSwitchTable = false;
                  this.store.dispatch(
                    KioskActions.setSwitchTable({ isswitchTable: false })
                  );
                  this.tableCartItems = [];
                  this.subOrderData = t?.aProperties?.orderData?.subOrderData;
                  this.store.dispatch(
                    KioskActions.updateKitchenSentData({
                      kitchenData: this.subOrderData,
                    })
                  );
                  this.resetCartValues();
                  this.cartOrderId = t?.aProperties?.orderData;
                  this.selectedTableData = t;
                  this.showSelectedTableData = t;
                  this.currentTableOrderDocId = t?.aProperties?.orderDocId;
                  this.currentTableId = t?._id;
                  this.selectedTableData['status'] =
                    t?.aProperties?.status ?? 'available';
                  this.cartOrderIdTotalTax =
                    t?.aProperties?.orderData?.payment?.price?.totalTaxAmt;
                  this.cartTax = t?.aProperties?.orderData?.payment?.price?.tax;
                  if (t?.aProperties?.status === 'available') {
                    this.showtableData = false;
                    this.cartOrderId = undefined;
                    if (this.SelectBtnValue === DINETYPE.TABLE) {
                      this.showTableModal = true;
                      this.closeCashModal();
                      this.isAvailableTableNotSelected = true;
                    }
                    this.messageService.add({
                      severity: 'success',
                      summary: `Order Updated`,
                      key: 'kiosk-order-page',
                    });
                  }
                }
              }
            }
          }
        },
        error: (err) => {
          this.showLoader = false;
          this.messageService.add({
            severity: 'error',
            key: 'kiosk-order-page',
            summary: 'Error',
            detail: err?.toString(),
          });
        },
      })
    );
    this.selectedLang = localStorage.getItem('user_language');
    this.translatePipe = new TranslatePipe(this.translate, this.cd);
  }

  @LogArgs()
  reloadApplication() {
    window.location.reload();
  }

  @LogArgs()
  retryConnTOPrinter(ip: unknown) {
    this.printer
      .retryConnToPrinter(ip as string)
      .then((_r) => {
        this.messageService.add({
          severity: 'success',
          summary: `Printer status refreshed, ${ip}`,
          key: 'kiosk-order-page',
        });
      })
      .catch((er) => {
        this.messageService.add({
          severity: 'error',
          summary: `Printer Error, ${ip}, ${er.toString()}`,
          key: 'kiosk-order-page',
        });
      });
  }

  reSync() {
    document.location.reload();
  }

  @LogArgs()
  removeCartItem(_id: string) {
    this.store.dispatch(
      KioskActions.removeCartItem({
        _id,
        isTable: this.showtableData,
      })
    );
    if (this.showtableData) {
      const obj = {
        _id: _id,
        remove: true,
      };
      this.updateTableData(obj);
    }

    this.showTaxBreakup = !this.cartOrderId?.payment?.price?.total;
  }

  @LogArgs()
  resetCartValues() {
    // this.showTaxBreakup = false;
    if (this.showtableData) {
      this.tableCartItems = [];
      if (!this.cartOrderId?.payment?.price?.total) {
        this.showTaxBreakup = true;
      }
    }
    this.store.dispatch(KioskActions.resetCartValues());
  }

  @LogArgs()
  setSelectedCartItem(item) {
    this.selectedCartItem = item;
  }

  @LogArgs()
  toggleTaxDetails() {
    this.showTaxDetails = !this.showTaxDetails;
  }

  @LogArgs()
  getSelectedCategory(data, idx, catData) {
    this.categoryIdStr = '';
    if (this.selectedCategoryIdx === idx) {
      this.selectedCategoryData = {
        ...this.selectedCategoryData,
        _id: '',
      };
      this.selectedCategoryIdx = -1;
    } else {
      this.selectedCategoryData = catData;
      this.categoryIdStr = data;
      this.selectedCategoryIdx = idx;
      this.groupIdStr = '';
    }
  }

  @LogArgs()
  redirectToProducts() {
    this.searchedProduct = undefined;
  }

  @LogArgs()
  incrementCartQty(product: IProductData) {
    // const storeId = this.current_branch['_id'];
    const productArray = [
      {
        _id: '',
        quantity: 0,
      },
    ];
    this.showTaxDetails = false;
    productArray[0]._id = product.productInfo._id;
    productArray[0].quantity = product.qty + 1;
    // if (this.kioskSettings?.inventoryManagement?.stockCheck) {
    //   this.store.dispatch(
    //     KioskActions.quantityCheck({
    //       productArray,
    //       storeId,
    //     })
    //   );
    //   this.quantityStatusSubscription$ = this.store
    //     .select(KioskSelectors.getQuantityStatus)
    //     .subscribe((quantityStatus) => {
    //       if (quantityStatus.status) {
    //         this.store.dispatch(
    //           KioskActions.incrementQtyViaProduct({
    //             product,
    //             isTable: this.showtableData,
    //           })
    //         );
    //         if (this.showtableData) {
    //           const obj = {
    //             item: product,
    //             increase: true,
    //           };
    //           this.updateTableData(obj);
    //         }
    //         this.selectedCartItem = this.selectedCartItem
    //           ? {
    //               ...this.selectedCartItem,
    //               qty: this.selectedCartItem.qty + 1,
    //             }
    //           : this.selectedCartItem;
    //       } else if (quantityStatus.status === false) {
    //         this.outOfStockMsg = quantityStatus.statusMsg;
    //         this.showOutOfStock = true;
    //       }
    //       this.quantityStatusSubscription$?.unsubscribe();
    //       quantityStatus.status !== null &&
    //         this.store.dispatch(KioskActions.quantityCheckDataReset());
    //     });
    // } else {
    this.store.dispatch(
      KioskActions.incrementQtyViaProduct({
        product,
        isTable: this.showtableData,
      })
    );
    if (this.showtableData) {
      const obj = {
        item: product,
        increase: true,
      };
      this.updateTableData(obj);
    }
    this.selectedCartItem = this.selectedCartItem
      ? {
        ...this.selectedCartItem,
        qty: this.selectedCartItem.qty + 1,
      }
      : this.selectedCartItem;
    // }
    if (this.showTaxBreakup) {
      this.showTaxBreakup = false;
    }
  }

  @LogArgs()
  decrementCartQty(product: any) {
    if (!product.qty) return;
    this.showTaxDetails = false;
    this.store.dispatch(
      KioskActions.decrementQtyViaProduct({
        product,
        isTable: this.showtableData,
      })
    );
    if (this.showtableData) {
      const obj = {
        item: product,
        increase: false,
      };
      this.updateTableData(obj);
    }
    this.selectedCartItem = this.selectedCartItem
      ? {
        ...this.selectedCartItem,
        qty: this.selectedCartItem.qty - 1,
      }
      : this.selectedCartItem;
    this.cartItemsSubscription$ = this.cartItems$.subscribe(
      (data: IProductData[]) => {
        this.cartItemsSubscription$?.unsubscribe();
        !data.length && this.redirectToProducts();
      }
    );

    if (this.showTaxBreakup) {
      this.showTaxBreakup = false;
    }
  }

  @LogArgs()
  updateShowTaxBreakup(event: boolean) {
    this.showTaxBreakup = event;
  }

  @LogArgs()
  ngOnDestroy() {
    this.productSubscription?.forEach((s) => s?.unsubscribe());
    this.quantityStatusSubscription$?.unsubscribe();
    this.cartItemsSubscription$?.unsubscribe();
  }

  @LogArgs()
  setPlaceholderForSearch() {
    if (window.innerWidth >= 600 && window.innerWidth <= 900) {
      this.placeholderValue = 'Search';
    } else {
      this.placeholderValue = 'Search for Product';
    }
  }

  @LogArgs()
  getTotalTax() {
    let totalTax = 0;
    this.cartOrderId?.payment?.price?.tax?.forEach((o) => {
      totalTax += o.amt;
    });

    return totalTax.toFixed(2);
  }

  @LogArgs()
  checkoutCart(action = false) {
    if (!this.tableCartItems?.length) {
      // this.disableEnableDineTypeBtn('disable');
      this.showWarningBorder = false;
      // const storeId = this.current_branch['_id'];
      let productArray = [];

      let cartItemsCopy = new Array<IProductData>();
      this.cartItemsSubscription$ = this.cartItems$.subscribe(
        (data: IProductData[]) => {
          cartItemsCopy = data?.filter((item: IProductData) => {
            return item['qty'] > 0;
          });
          this.cartItemsSubscription$?.unsubscribe();
        }
      );

      if (!this.subOrderData?.length) {
        for (let idx = 0; idx < cartItemsCopy.length; idx++) {
          productArray[idx] = {
            _id: cartItemsCopy[idx].productInfo._id,
            quantity: cartItemsCopy[idx].qty,
          };
        }
      }

      if (this.subOrderData?.length) {
        productArray = this.getProductPayloadForTable();
      }
      // if (!this.kioskSettings?.inventoryManagement?.stockCheck) {
      //   this.store.dispatch(
      //     KioskActions.quantityCheck({
      //       productArray,
      //       storeId,
      //     })
      //   );
      //   this.quantityStatusSubscription$ = this.store
      //     .select(KioskSelectors.getQuantityStatus)
      //     .subscribe((quantityStatus) => {
      //       if (quantityStatus.status) {
      //         this.showLoader = true;
      //         let products = [];
      //         this.cartItemsSubscription$ = this.cartItems$.subscribe(
      //           (data: IProductData[]) => {
      //             data?.forEach((item: IProductData) => {
      //               if (item['qty'] > 0 && !this.subOrderData?.length) {
      //                 products.push({
      //                   _id: item['productInfo']['_id'],
      //                   all: item,
      //                   quantity: item['qty'],
      //                 });
      //               }
      //               if (this.subOrderData?.length) {
      //                 products = productArray;
      //               }
      //             });
      //             this.cartItemsSubscription$?.unsubscribe();
      //           }
      //         );
      //         this.showTaxBreakup = true;
      //         const obj = {
      //           dineType: this.SelectBtnValue,
      //           tableNo: this.showSelectedTableData?.tableNo ?? 0,
      //           tableId: this.showSelectedTableData?._id,
      //           table: this.showSelectedTableData,
      //           isTableOrder: !!this.showSelectedTableData?.tableNo,
      //         };
      //         if (this.showSelectedTableData?.orderData) {
      //           if (!products?.length && this.subOrderData?.length) {
      //             products = this.getProductPayloadForTable();
      //           }
      //           this.kioskOrderService
      //             .checkoutUpdatedCartOrders(
      //               this.showSelectedTableData?.orderData['_id'],
      //               products,
      //               this.current_branch['_id'],
      //               obj,
      //               action
      //             )
      //             .subscribe({
      //               next: (data) => {
      //                 this.cartOrderId = data;
      //                 this.cartOrderIdTotalTax = this.getTotalTax();
      //                 this.disableEnableDineTypeBtn('enable');
      //                 this.cartTax = this.cartOrderId?.payment?.price?.tax;
      //                 this.showLoader = false;
      //               },
      //               error: (err) => {
      //                 this.showLoader = false;
      //                 this.disableEnableDineTypeBtn('enable');
      //                 this.messageService.add({
      //                   severity: 'error',
      //                   key: 'kiosk-order-page',
      //                   summary: 'Error',
      //                   detail: err?.toString(),
      //                 });
      //               },
      //             });
      //         } else {
      //           if (!products?.length && this.subOrderData?.length) {
      //             products = this.getProductPayloadForTable();
      //           }
      //           this.kioskOrderService
      //             .checkoutCartOrders(products, this.current_branch['_id'], obj)
      //             .subscribe({
      //               next: (data) => {
      //                 this.cartOrderId = data;
      //                 this.cartOrderIdTotalTax = this.getTotalTax();
      //                 this.disableEnableDineTypeBtn('enable');
      //                 this.cartTax = this.cartOrderId?.payment?.price?.tax;
      //                 this.showLoader = false;
      //                 // this.kioskOrderService.initiatePayment(data['_id']).subscribe((res)=>{
      //                 //   this.proceedToBill = true;
      //                 //   this.showLoader = false;
      //                 //   // if(res['paymentGateway']['id'])
      //                 //     this.rzPaymentId = res['paymentGateway']['id'];
      //                 // }, ()=>{this.showLoader = false})
      //               },
      //               error: (err) => {
      //                 this.showLoader = false;
      //                 this.disableEnableDineTypeBtn('enable');
      //                 this.messageService.add({
      //                   severity: 'error',
      //                   key: 'kiosk-order-page',
      //                   summary: 'Error',
      //                   detail: err?.toString(),
      //                 });
      //               },
      //             });
      //         }
      //       } else if (quantityStatus.status === false) {
      //         this.outOfStockMsg = quantityStatus.statusMsg;
      //         this.showOutOfStock = true;
      //       }
      //       this.quantityStatusSubscription$?.unsubscribe();
      //       quantityStatus.status !== null &&
      //         this.store.dispatch(KioskActions.quantityCheckDataReset());
      //     });
      // } else {
      this.showLoader = true;
      let products = [];
      this.cartItemsSubscription$ = this.cartItems$.subscribe(
        (data: IProductData[]) => {
          data?.forEach((item: IProductData) => {
            if (item['qty'] > 0 && !this.subOrderData?.length) {
              products.push({
                _id: item['productInfo']['_id'],
                all: item,
                quantity: item['qty'],
              });
            }
            if (this.subOrderData?.length) {
              products = productArray;
            }
          });
          this.cartItemsSubscription$?.unsubscribe();
        }
      );
      this.showTaxBreakup = true;
      const obj = {
        dineType: this.SelectBtnValue,
        tableNo: this.showSelectedTableData?.tableNo ?? 0,
        tableId: this.showSelectedTableData?._id,
        table: this.showSelectedTableData,
        isTableOrder: !!this.showSelectedTableData?.tableNo,
      };
      if (this.cartOrderId?._id) {
        if (!products?.length && this.subOrderData?.length) {
          products = this.getProductPayloadForTable();
        }
        this.kioskOrderService
          .checkoutUpdatedCartOrders(
            this.cartOrderId?._id,
            products,
            this.current_branch['_id'],
            obj,
            action
          )
          .subscribe({
            next: (data) => {
              this.cartOrderId = data;
              this.cartOrderIdTotalTax = this.getTotalTax();
              this.disableEnableDineTypeBtn('enable');
              this.cartTax = this.cartOrderId?.payment?.price?.tax;
              this.showLoader = false;
            },
            error: (err) => {
              this.showLoader = false;
              this.disableEnableDineTypeBtn('enable');
              this.messageService.add({
                severity: 'error',
                key: 'kiosk-order-page',
                summary: 'Error',
                detail: err?.toString(),
              });
            },
          });
      } else {
        if (!products?.length && this.subOrderData.length) {
          products = this.getProductPayloadForTable();
        }
        this.kioskOrderService
          .checkoutCartOrders(products, this.current_branch['_id'], obj)
          .subscribe({
            next: (data) => {
              this.cartOrderId = data;
              this.cartOrderIdTotalTax = this.getTotalTax();
              this.disableEnableDineTypeBtn('enable');
              this.cartTax = this.cartOrderId?.payment?.price?.tax;
              this.showLoader = false;
            },
            error: (err) => {
              this.showLoader = false;
              this.disableEnableDineTypeBtn('enable');
              this.messageService.add({
                severity: 'error',
                key: 'kiosk-order-page',
                summary: 'Error',
                detail: err?.toString(),
              });
            },
          });
      }
      // }
    } else {
      this.showWarningBorder = true;
      setTimeout(() => {
        this.showWarningBorder = false;
      }, 5000);
    }

    this.showLoader = false;
  }

  @LogArgs()
  bypassThirdPartyServiceFlow() {
    this.showLoader = true;
    const products = [];
    this.cartItemsSubscription$ = this.cartItems$.subscribe(
      (data: IProductData[]) => {
        data?.forEach((item: IProductData) => {
          if (item['qty'] > 0 && !this.subOrderData?.length) {
            products.push({
              _id: item['productInfo']['_id'],
              all: item,
              quantity: item['qty'],
            });
          }
        });
        this.cartItemsSubscription$?.unsubscribe();
      }
    );
    const obj = {
      dineType: this.SelectBtnValue,
      tableNo: this.showSelectedTableData?.tableNo ?? 0,
      tableId: this.showSelectedTableData?._id,
      table: this.showSelectedTableData,
      isTableOrder: !!this.showSelectedTableData?.tableNo,
    };
    this.kioskOrderService
      .checkoutCartOrders(products, this.current_branch['_id'], obj)
      .subscribe({
        next: (data) => {
          this.cartOrderId = data;
          this.cartOrderIdTotalTax = this.getTotalTax();
          this.disableEnableDineTypeBtn('enable');
          this.cartTax = this.cartOrderId?.payment?.price?.tax;
          // const modifiedProductArray =  products.map((item) => item?.all);
          const obj = {
            amount: this.cartOrderId?.payment?.price?.total,
            pMode: 'cash',
          };
          this.goToPrintReciept(obj);
          this.showLoader = false;
        },
        error: (err) => {
          this.showLoader = false;
          this.disableEnableDineTypeBtn('enable');
          this.messageService.add({
            severity: 'error',
            key: 'kiosk-order-page',
            summary: 'Error',
            detail: err?.toString(),
          });
        },
      });
  }

  @LogArgs()
  closeAddOrderModal() {
    this.showLoader = true;
    this.showSelectedTableData = {};
    // this.SelectBtnValue = 'dineIn'
    this.showtableData = false;
    this.selectedCategoryData = undefined;
    if (!this.currentOrderPaid && this.cartOrderId?._id) {
      this.kioskOrderService
        .discardOrderId(
          this.currentTableOrderDocId ?? this.cartOrderId?._id,
          'checkout-view'
        )
        .subscribe(
          () => {
            this.searchedProduct = undefined;
            this.categoryIdStr = undefined;
            this.groupIdStr = undefined;
            this.cartOrderId = undefined;
            this.cartOrderIdTotalTax = 0;
            this.showQrScanner = {
              visibility: false,
              src: '',
            };
            this.resetCartValues();
            this.selectedCategoryIdx = -1;
          },
          () => {
            this.showLoader = false;
          }
        );
    } else {
      this.searchedProduct = undefined;
      this.categoryIdStr = undefined;
      this.groupIdStr = undefined;
      this.cartOrderId = undefined;
      this.cartOrderIdTotalTax = 0;
      this.showQrScanner = {
        visibility: false,
        src: '',
      };
      this.resetCartValues();
      this.showAddOrdersModal = true;
      this.selectedCategoryIdx = -1;
    }
    this.showLoader = false;
    this.currentOrderPaid = true;
  }

  @LogArgs()
  closeQRModal() {
    // this.closeRowUpdateCashModal();
    this.showQrScanner.visibility = false;
    clearInterval(this.checkPaymentStatusInterval);
  }

  @LogArgs()
  switchDineType() {
    this.isSwitchTable = false;
    this.store.dispatch(KioskActions.setSwitchTable({ isswitchTable: false }));
    this.showLoader = false;
  }

  @LogArgs()
  switchTable() {
    if (!this.cartOrderId) {
      this.messageService.add({
        severity: 'error',
        key: 'kiosk-order-page',
        summary: 'Error',
        detail: 'You cannot switch table until order is created.',
      });
      return;
    }
    this.isSwitchTable = true;
    this.store.dispatch(KioskActions.setSwitchTable({ isswitchTable: true }));
    this.showTableModal = true;
    this.getTablesWithFloorId();
  }

  @LogArgs()
  setLoader(v) {
    this.showLoader = v;
  }

  @LogArgs()
  paidByCash() {
    if (this.subOrderData?.length) {
      this.store.dispatch(
        KioskActions.filledStateWithTableData({ tableData: this.subOrderData })
      );
    }
    // const storeId = this.current_branch['_id'];
    const productArray = [];
    let cartItemsCopy = new Array<IProductData>();
    this.cartItemsSubscription$ = this.cartItems$.subscribe(
      (data: IProductData[]) => {
        cartItemsCopy = data;
        this.cartItemsSubscription$?.unsubscribe();
      }
    );
    for (let idx = 0; idx < cartItemsCopy.length; idx++) {
      productArray[idx] = {
        _id: cartItemsCopy[idx].productInfo._id,
        quantity: cartItemsCopy[idx].qty,
      };
    }

    // if (this.kioskSettings?.inventoryManagement?.stockCheck) {
    //   this.store.dispatch(
    //     KioskActions.quantityCheck({
    //       productArray,
    //       storeId,
    //     })
    //   );
    //   this.quantityStatusSubscription$ = this.store
    //     .select(KioskSelectors.getQuantityStatus)
    //     .subscribe((quantityStatus) => {
    //       if (quantityStatus.status) {
    //         this.showCashModal = true;
    //         this.displayPayementStatusModal = {
    //           visibility: false,
    //           orderId:
    //             this.showSelectedTableData?.aProperties?.orderData?.orderId ||
    //             this.cartOrderId?.orderId,
    //           id: null,
    //           amt: Number(this.cartOrderId?.payment?.price?.total?.toFixed(2)),
    //           accountId: null,
    //         };
    //       } else if (quantityStatus.status === false) {
    //         this.outOfStockMsg = quantityStatus.statusMsg;
    //         this.showOutOfStock = true;
    //       }
    //       this.quantityStatusSubscription$?.unsubscribe();
    //       quantityStatus.status !== null &&
    //         this.store.dispatch(KioskActions.quantityCheckDataReset());
    //     });
    // } else {
    this.showCashModal = true;
    this.displayPayementStatusModal = {
      visibility: false,
      orderId:
        this.showSelectedTableData?.aProperties?.orderData?.orderId ||
        this.cartOrderId?.orderId,
      id: null,
      amt: Number(this.cartOrderId?.payment?.price?.total?.toFixed(2)),
      accountId: null,
    };
    // }
    this.TaxBreakupDetails = {
      orderDetails: this.cartOrderId,
      formattedTotal: this.calculateFormattedTotal(),
      cartTax: this.cartTax,
      totalTax: this.getTotalTax(),
    };
  }

  @LogArgs()
  payOnline() {
    if (this.subOrderData?.length) {
      this.store.dispatch(
        KioskActions.filledStateWithTableData({ tableData: this.subOrderData })
      );
    }
    // const storeId = this.current_branch['_id'];
    const productArray = [];
    let cartItemsCopy = new Array<IProductData>();
    this.cartItemsSubscription$ = this.cartItems$.subscribe(
      (data: IProductData[]) => {
        cartItemsCopy = data;
        this.cartItemsSubscription$?.unsubscribe();
      }
    );
    for (let idx = 0; idx < cartItemsCopy.length; idx++) {
      productArray[idx] = {
        _id: cartItemsCopy[idx].productInfo._id,
        quantity: cartItemsCopy[idx].qty,
      };
    }
    // if (this.kioskSettings?.inventoryManagement?.stockCheck) {
    //   this.store.dispatch(
    //     KioskActions.quantityCheck({
    //       productArray,
    //       storeId,
    //     })
    //   );
    //   this.quantityStatusSubscription$ = this.store
    //     .select(KioskSelectors.getQuantityStatus)
    //     .subscribe((quantityStatus) => {
    //       if (quantityStatus.status) {
    //         this.showLoader = true;
    //         this.kioskOrderService
    //           .getQrCode(this.cartOrderId?.['_id'])
    //           .subscribe(
    //             (data) => {
    //               this.showQrScanner = {
    //                 visibility: true,
    //                 src: data?.data
    //                   ? `data:image/png;base64,${data?.data}`
    //                   : data['image_url'],
    //               };
    //               this.showLoader = false;
    //               this.checkPaymentStatusInterval = setInterval(() => {
    //                 this.kioskOrderService
    //                   .getPaymentStatus(this.cartOrderId?.['_id'])
    //                   .subscribe(
    //                     (data) => {
    //                       if (data['paymentStatus']) {
    //                         this.showLoader = false;
    //                         this.showQrScanner.visibility = false;
    //                         this.paymentIsSuccessfull = true;
    //                         this.handleAvailableDineTypes();
    //                         this.currentOrderPaid = true;
    //                         this.closeAddOrderModal();
    //                         this.messageService.add({
    //                           severity: 'success',
    //                           key: 'kiosk-order-page',
    //                           summary: 'Success',
    //                           detail: 'Successfully Paid...!',
    //                         });
    //                       }
    //                     },
    //                     () => {
    //                       this.showLoader = false;
    //                     }
    //                   );
    //               }, 1000);
    //             },
    //             () => {
    //               this.showLoader = false;
    //               this.messageService.add({
    //                 severity: 'error',
    //                 key: 'kiosk-order-page',
    //                 summary: 'Error',
    //                 detail: 'QR Code Generation Failed, try again...!',
    //               });
    //             }
    //           );
    //       } else if (quantityStatus.status === false) {
    //         this.outOfStockMsg = quantityStatus.statusMsg;
    //         this.showOutOfStock = true;
    //       }
    //       this.quantityStatusSubscription$?.unsubscribe();
    //       quantityStatus.status !== null &&
    //         this.store.dispatch(KioskActions.quantityCheckDataReset());
    //     });
    // } else {
    this.showLoader = true;
    this.kioskOrderService.getQrCode(this.cartOrderId?.['_id']).subscribe({
      next: (data) => {
        this.showQrScanner = {
          visibility: true,
          src: data?.data
            ? `data:image/png;base64,${data?.data}`
            : data['image_url'],
        };
        this.showLoader = false;
        this.checkPaymentStatusInterval = setInterval(() => {
          this.kioskOrderService
            .getPaymentStatus(this.cartOrderId?.['_id'])
            .subscribe({
              next: (data) => {
                if (data['paymentStatus']) {
                  this.showLoader = false;
                  this.messageService.add({
                    severity: 'success',
                    key: 'kiosk-order-page',
                    summary: 'Success',
                    detail: 'Successfully Paid...!',
                  });
                  this.showQrScanner.visibility = false;
                  this.paymentIsSuccessfull = true;
                  this.currentOrderPaid = true;
                  this.handleAvailableDineTypes();
                  this.closeAddOrderModal();
                }
              },
              error: () => (this.showLoader = false),
            });
        }, 1000);
      },
      error: () => {
        this.showLoader = false;
        this.messageService.add({
          severity: 'error',
          key: 'kiosk-order-page',
          summary: 'Error',
          detail: 'QR Code Generation Failed, try again...!',
        });
      },
    });
    // }
  }

  @LogArgs()
  closeCashModal() {
    this.showCashModal = false;
    this.showCardModal = false;
  }

  @LogArgs()
  goToPrintReciept(obj: IPayByCashCartParam) {
    this.showLoader = true;
    let orderId: string;
    if (!this.currentTableOrderDocId) {
      orderId = this.cartOrderId?.['_id'];
    } else {
      orderId = this.currentTableOrderDocId;
    }

    this.kioskOrderService.setOrderAsPicked([orderId], obj).subscribe({
      next: (res: any) => {
        this.currentOrderPaid = true;
        this.closeAddOrderModal();
        this.closeCashModal();
        this.SelectBtnValue = '';
        this.currentTableOrderDocId = undefined;
        this.currentTableId = '';
        this.handleAvailableDineTypes();
        this.subOrderData = [];
        this.tableCartItems = [];
        this.isReloadRequiredMemo();
        if (this.additionalDineTypes?.length > 0) {
          this.updateDineTypeSelectBtn(null, '');
        }
        this.messageService.add({
          severity: 'success',
          key: 'kiosk-order-page',
          summary: 'Receipt Sent',
          detail: res?.msg,
        });
      },
      error: (err) => {
        this.showLoader = false;
        if (err)
          this.messageService.add({
            severity: 'error',
            key: 'kiosk-order-page',
            summary: 'Error',
            detail: err?.toString(),
          });
      },
    });
  }

  @LogArgs()
  openTableOccupancyDialog() {
    this.closeAddOrderModal();
    this.checkOccupiedTable();
    this.resetCartValues();
  }

  @LogArgs()
  confirm(key: string) {
    this.confirmationService?.close();
    this.popUpKey = key;
    const target = event.target;

    setTimeout(() => {
      this.confirmationService.confirm({
        target: target as EventTarget,
        message:
          key === 'dialogClose'
            ? 'Do you really want to close the page ?'
            : key === 'tableSwitch'
              ? this.translatePipe.transform('app.pos.tableSwitchConfirm')
              : key === 'discardBtn'
                ? this.translatePipe.transform('app.pos.discardOrderConfirm')
                : '',

        icon: 'pi pi-exclamation-triangle',
        key: key,
        accept: () => {
          if (key === 'dialogClose') {
            this.showAddOrdersModal = false;
          } else if (key === 'tableSwitch') {
            this.currentOrderPaid = false;
            this.openTableOccupancyDialog();
          } else if (key === 'discardBtn') {
            this.currentOrderPaid = false;
            this.currentOrderPaid = false;
            if (this.dineTypeSelectBtn?.[0]?.value === DINETYPE.TABLE) {
              this.showTableModal = true;
            } else {
              this.updateDineTypeSelectBtn(null, '');
              this.showWallpaper = true;
            }
            this.subOrderData = [];
            this.closeAddOrderModal();
            this.showWallpaper = true;
            this.isReloadRequiredMemo();
          }
        },
        reject: () => {
          if (key === 'tableSwitch') {
            this.TaxBreakupDetails = true;
            const dineInOption = this.dineTypeSelectBtn.find(
              (option) => option.value === DINETYPE.DINE_IN
            );
            if (dineInOption) {
              this.SelectBtnValue = DINETYPE.DINE_IN;
            } else {
              const dineOutOption = this.dineTypeSelectBtn.find(
                (option) => option.value === DINETYPE.DINE_OUT
              );
              if (dineOutOption) {
                this.SelectBtnValue = DINETYPE.DINE_OUT;
              } else {
                this.SelectBtnValue = this.selectedOrderTypeValue;
              }
            }
          }
        },
      });
    }, 200);
  }

  @LogArgs()
  handleSelectButtonDeselection() {
    this.dineTypeSelectBtn = this.dineTypeSelectBtn.map((dineType) => {
      dineType.isSelected = dineType?.value === this.SelectBtnValue;
      return dineType;
    });
  }

  @LogArgs()
  updateDineTypeSelectBtn(otherServiceSelectedVal, mainSelectedVal) {
    const updateItem = (label, value) => ({
      label,
      value,
      disabled: false,
      isSelected: false,
      key: DINETYPE.OTHER_SERVICES,
    });

    this.dineTypeSelectBtn = this.dineTypeSelectBtn.map((g) => {
      const isDineType =
        g?.value === DINETYPE.DINE_IN ||
        g?.value === DINETYPE.DINE_OUT ||
        g?.value === DINETYPE.TABLE;
      if (!isDineType) {
        const updatedItem =
          otherServiceSelectedVal !== null
            ? updateItem(
              otherServiceSelectedVal,
              otherServiceSelectedVal !== 'app.pos.Other'
                ? otherServiceSelectedVal
                : null
            )
            : updateItem('app.pos.Other', DINETYPE.OTHER_SERVICES);
        this.SelectBtnValue =
          otherServiceSelectedVal !== null
            ? otherServiceSelectedVal
            : mainSelectedVal?.option?.value;
        return updatedItem;
      }
      return g;
    });
  }

  @LogArgs()
  checkSelectedButton(val, otherService = null) {
    if (val?.option?.label === 'app.pos.Other') {
      return;
    }
    const isDineTypeValid = otherService
      ? ![DINETYPE.DINE_IN, DINETYPE.DINE_OUT, DINETYPE.TABLE].includes(val)
      : ![DINETYPE.DINE_IN, DINETYPE.DINE_OUT, DINETYPE.TABLE].includes(
        val?.option?.value
      );
    if (isDineTypeValid) {
      this.updateDineTypeSelectBtn(val?.option?.label ?? val, 'null');
    } else {
      const hasOtherServices = this.dineTypeSelectBtn.every(
        (item) => item.value !== 'otherServices'
      );
      if (hasOtherServices) {
        this.updateDineTypeSelectBtn(null, val);
      }
    }
    this.cancelKitchenItemsMod();
    this.SelectBtnValue = val?.option?.value ?? val;
    this.selectedInKitchenProdcut = null;
    if (this.SelectBtnValue === DINETYPE.TABLE) {
      this.getTablesWithFloorId();
      if (!this.showtableData && this.cartOrderId?._id) {
        this.confirm('tableSwitch');
        return;
      }
      this.checkOccupiedTable();
      if (!this.showSelectedTableData) {
        this.showSelectedTableData = {};
        this.resetCartValues();
      }
    } else {
      this.currentTableOrderDocId = undefined;
      this.isAvailableTableNotSelected = true;
      this.showSelectedTableData = {};
      this.selectedTableData = undefined;
      if (this.showtableData === true) {
        this.resetCartValues();
        this.showtableData = false;
        this.cartOrderId = '';
      } else if (this.cartOrderId?._id) {
        this.showTaxBreakup = false;
      }
    }

    if (!this.showtableData) {
      this.tableCartItems = [];
      this.subOrderData = [];
    }
  }

  @LogArgs()
  onTableSelect(e) {
    this.isSwitchTable = false;
    this.store.dispatch(KioskActions.setSwitchTable({ isswitchTable: false }));
    this.selectedTableData = e;
    if (e?.aProperties?.status === 'available') {
      this.showEditOrderOption = true;
    } else {
      this.tableCartItems = [];
      this.showSelectedTableData = e;
      this.currentTableOrderDocId = e?.aProperties?.orderDocId;
      this.currentTableId = e?._id;
      // this.editOrBookOrder(e);
      this.subOrderData = e?.aProperties?.orderData?.subOrderData;
      this.store.dispatch(
        KioskActions.updateKitchenSentData({ kitchenData: this.subOrderData })
      );
      this.cartOrderId = e?.aProperties?.orderData;
      this.cartOrderIdTotalTax =
        e?.aProperties?.orderData?.payment?.price?.totalTaxAmt;
      this.subOrderData?.map((_, idx) => {
        this.activeIndex.push(idx);
      });
      this.store.dispatch(KioskActions.resetCartValues());
      this.cartTax = e?.aProperties?.orderData?.payment?.price?.tax;
      this.showTaxBreakup = true;
      this.showTableModal = false;
      this.showtableData = true;
    }
  }

  @LogArgs()
  openConfirmSwitchDialog(data: any) {
    this.switchToTable = data;
  }

  @LogArgs()
  switchTableData(e) {
    this.showLoader = true;
    const obj = {
      sourceTableId: this.showSelectedTableData?._id,
      destinationTableId: e?._id,
    };
    this.kioskOrderService.switchTableOrder(obj).subscribe({
      next: (res: any) => {
        this.showLoader = false;
        this.showSelectedTableData = res?.destinationTableData;
        this.currentTableOrderDocId =
          this.showSelectedTableData?.aProperties?.orderDocId;
        this.currentTableId = this.showSelectedTableData?._id;
        this.selectedTableData = res?.destinationTableData;
        this.switchToTable = null;
        this.isSwitchTable = false;
        this.store.dispatch(
          KioskActions.setSwitchTable({ isswitchTable: false })
        );
        this.getTablesWithFloorId();
        this.messageService.add({
          severity: 'success',
          key: 'kiosk-order-page',
          summary: 'Success',
          detail: res?.msg,
        });
        if (this.cartOrderId) {
          this.checkoutCart(false);
        }
        this.showTableModal = false;
      },
      error: (error) => {
        this.messageService.add({
          severity: 'error',
          key: 'kiosk-order-page',
          summary: 'Error',
          detail: error?.msg,
        });
        this.showTableModal = false;
        this.showLoader = false;
        this.switchToTable = null;
      },
    });
  }

  @LogArgs()
  editOrBookOrder(e) {
    if (
      e?.aProperties?.status === 'occupied' ||
      e?.aProperties?.status === TABLE_STATUS.RECEIPT_GEN
    ) {
      this.activeIndex = [];
      this.subOrderData = e?.aProperties?.orderData?.subOrderData;
      this.store.dispatch(
        KioskActions.updateKitchenSentData({ kitchenData: this.subOrderData })
      );
      this.subOrderData?.map((_, idx) => {
        this.activeIndex.push(idx);
      });
      if (this.tableCartItems?.length == 0) {
        this.store.dispatch(KioskActions.resetCartValues());
      }
      this.cartOrderId = e?.aProperties?.orderData;
      this.cartOrderIdTotalTax = this.getTotalTax();
      if (this.cartOrderId) {
        this.checkoutCart(false);
      }
    } else {
      this.isAvailableTableNotSelected = false;
      this.showEditOrderOption = false;
      this.cartOrderId = e?.aProperties?.orderData;
      this.tableCartItems = [];
      this.subOrderData = [];
      this.currentTableOrderDocId = e?.aProperties?.orderDocId;
      this.currentTableId = e?._id;
      this.store.dispatch(KioskActions.resetCartValues());
      this.showSelectedTableData = e;
    }
    this.showTableModal = false;
    this.showtableData = true;
  }

  @LogArgs()
  outOfStockCheck(value) {
    // this.showOutOfStock = true;
    if (value) {
      // this.outOfStockMsg = value;
    }
  }

  @LogArgs()
  calculateFormattedTotal(): string {
    if (
      this.cartOrderId?.payment?.price?.items?.length > 0 &&
      typeof this.cartOrderId?.payment?.price?.total === 'number'
    ) {
      const formattedTotal = (
        this.cartOrderId.payment.price.total - Number(this.getTotalTax())
      ).toFixed(2);

      return '₹' + formattedTotal;
    }

    return '₹0.00';
  }

  @LogArgs()
  checkOccupiedTable() {
    this.showLoader = true;
    this.isSwitchTable = false;
    this.store.dispatch(KioskActions.setSwitchTable({ isswitchTable: false }));
    this.showTableModal = true;
    this.showTaxBreakup = false;
    this.showLoader = false;
  }

  @LogArgs()
  changeSelectedViewValue(key, e) {
    this.selectedUiConfig[key] = e?.value;
    if (key === 'catPosition') {
      if (e?.value === 'catLeft') {
        this.layoutt = 'horizontal';
        this.panelSize = [30, 70];
      } else if (e?.value === 'catBottom') {
        this.layoutt = 'vertical';
        this.panelSize = [70, 30];
      }
    }
    localStorage.setItem(
      'kiosk-ui-view',
      JSON.stringify(this.selectedUiConfig)
    );
  }

  @LogArgs()
  updateTableData(data: any) {
    const idx = this.tableCartItems?.findIndex(
      (v) => v?.productInfo?._id === data?.item?.productInfo?._id
    );
    if (data.increase) {
      const updateProd = { ...this.tableCartItems[idx] };
      if (idx !== -1) {
        updateProd.qty++;
        this.tableCartItems[idx] = updateProd;
      } else {
        const updatePord = { ...data.item };
        updatePord.qty = 1;
        this.tableCartItems.unshift(updatePord);
      }
    } else if (data.remove) {
      this.tableCartItems = this.tableCartItems.filter(
        (v) => v?.productInfo?._id !== data?._id
      );
    } else {
      if (idx !== -1) {
        const updateProd = { ...this.tableCartItems[idx] };
        if (updateProd.qty === 1) {
          this.tableCartItems.splice(idx, 1);
        } else {
          updateProd.qty--;
          this.tableCartItems[idx] = updateProd;
        }
      }
    }
  }

  @LogArgs()
  updateTableCall() {
    if (!this.currentTableId) {
      this.currentTableId = this.cartOrderId?.tableData?._id;
    }
    const prodData = this.tableCartItems.reduce((acc, curr) => {
      const obj = {
        _id: curr?.productInfo?._id,
        quantity: curr?.qty,
        all: curr,
        customInstruction: curr?.customInstruction,
        defaultInstruction: curr?.defaultInstruction,
      };
      acc.push(obj);
      return acc;
    }, []);
    this.showLoader = true;
    this.kioskOrderService
      .submitTableOrder(
        this.currentTableId,
        prodData,
        this.currentTableOrderDocId
      )
      .subscribe({
        next: (data: any) => {
          if (data) {
            this.selectedInKitchenProdcut = null;
            this.showLoader = false;
            this.tableCartItems = [];
            this.currentTableOrderDocId = data?._id;
            this.subOrderData = data?.subOrderData;
            this.cartOrderId = data;
            // make checkout update call when a new chunck is added into table
            if (this.cartOrderId) {
              this.checkoutCart(true);
            }
            this.store.dispatch(
              KioskActions.updateKitchenSentData({
                kitchenData: this.subOrderData,
              })
            );
            for (let j = 0; j < data.orderDetails?.items?.length; j++) {
              this.store.dispatch(
                KioskActions.updateTableCart({
                  prodId: data.orderDetails.items[j]?._id,
                  isTable: this.showTableModal,
                })
              );
            }
          } else {
            this.showLoader = false;
          }
        },
        error: (err) => {
          this.showLoader = false;
          this.messageService.add({
            severity: 'error',
            key: 'kiosk-order-page',
            summary: 'Error',
            detail: err?.toString(),
          });
        },
      });
  }

  // tableSub?: Subscription;

  @LogArgs()
  getTablesWithFloorId(defaultFloorId?: string) {
    localStorage.setItem('currentFloor', JSON.stringify(this.currentFloor));
    this.showLoader = true;
    if (defaultFloorId) {
      // this.currentFloor = defaultFloorId
    }
    const status = this.isSwitchTable ? 'available' : '';
    if (this.currentFloor) {
      this.store.dispatch(
        KioskActions.getMaestroTable({
          areaId: this.currentFloor,
          status: status,
          remote: false,
        })
      );
    } else {
      this.showLoader = false;
    }
  }

  @LogArgs()
  assignUpdatedTime(res) {
    return res?.map((tableData) => {
      if (
        tableData?.aProperties?.status === 'occupied' ||
        tableData?.aProperties?.status === TABLE_STATUS.RECEIPT_GEN
      ) {
        const currentTime = new Date().toISOString();
        const createdTimeVal =
          tableData?.aProperties?.orderData?.subOrderData?.[0]?.updated;
        const createdTime = createdTimeVal
          ? new Date(createdTimeVal).toISOString()
          : currentTime;
        const { timePassed, endTime } =
          this.dateFormatter.getTimeDifferenceFromString(
            currentTime,
            createdTime
          );

        return {
          ...tableData,
          timeDetails: {
            timePassed,
            createdTime: endTime,
          },
        };
      }
      return tableData;
    });
  }

  @LogArgs()
  setDineType(type, otherService = null) {
    if (otherService) {
      this.updateDineTypeSelectBtn(otherService?.value, null);
    }
    this.selectedOrderTypeValue = type;
    this.tableCartItems = [];
    this.subOrderData = [];
    if (type === DINETYPE.TABLE) {
      this.getTablesWithFloorId(this.defaultFloorId);
      this.openTableOccupancyDialog();
    }
    this.SelectBtnValue = type;
    this.showWallpaper = false;
    this.handleSelectButtonDeselection();
  }

  @LogArgs()
  isReloadRequiredMemo() {
    if (this.isReloadModalVisible) {
      this.reloadAgainState = true;
    }
    this.isReloadModalVisible = false;
  }

  @LogArgs()
  getAdditionalDineServices() {
    if (this.kioskSettings?.posUISettings?.dineType?.['otherServices']) {
      this.kioskService.getAdditionalDineServices().subscribe({
        next: (data: IAdditionalDineType) => {
          this.store.dispatch(
            KioskActions.setAdditionalDineType({ data: data })
          );
          this.additionalDineTypes = data?.map(({ assetDetails }) => {
            const name = assetDetails?.['name'];
            return {
              value: name,
              label: name,
              option: { value: name },
              key: DINETYPE.OTHER_SERVICES,
            };
          });
          if (
            this.additionalDineTypes?.length &&
            this.kioskSettings?.posUISettings?.dineType?.['otherServices']
          ) {
            const item = {
              label: 'app.pos.Other',
              value: 'other',
              disabled: false,
              isSelected: false,
              key: DINETYPE.OTHER_SERVICES,
            };
            const isValueExist = this.dineTypeSelectBtn.some(
              (option) => option?.value === item?.value
            );
            if (!isValueExist) {
              this.dineTypeSelectBtn.push(item);
            }
          }
        },
        error: (err) => {
          this.messageService.add({
            severity: 'error',
            key: 'kiosk-order-page',
            summary: 'Error',
            detail: err?.toString(),
          });
        },
        complete: () => {
          this.handleAvailableDineTypes();
        },
      });
    } else {
      this.handleAvailableDineTypes();
    }
  }

  @LogArgs()
  getProductPayloadForTable() {
    const payLoadProd = [];
    let idx = 0;
    for (let i = 0; i < this.subOrderData.length; i++) {
      for (let j = 0; j < this.subOrderData[i].items.length; j++) {
        payLoadProd[idx] = {
          _id: this.subOrderData[i].items[j]?._id,
          quantity: this.subOrderData[i].items[j]?.quantity,
          all: this.subOrderData[i].items[j]?.all,
          assetsLinked:
            this.subOrderData[i].items[j]?.all?.productInfo?.assetsLinked,
        };
        idx++;
      }
    }
    return payLoadProd;
  }

  @LogArgs()
  clearCart(key) {
    this.confirmationService?.close();
    this.popUpKey = key;
    const target = event.target;

    setTimeout(() => {
      this.confirmationService.confirm({
        target: target as EventTarget,
        message: this.translatePipe.transform('app.pos.clearTheCartConfirm'),
        icon: 'pi pi-exclamation-triangle',
        key: key,
        accept: () => {
          this.resetCartValues();
          !this.showtableData && this.handleAvailableDineTypes();
        },
        reject: () => { },
      });
    }, 200);
  }

  checkPrinterBeforePrintOrderReceipt(event: Event) {
    if (this.printerStatus.allConnected) {
      this.printOrderReceipt();
    } else {
      const Asset = this.receiptTemplate.assetsInfo.find((e) => !e?.isKot);
      if (
        Asset &&
        this.printerStatus?.status?.[Asset?.ipaddress]?.type === 'kAgent'
      ) {
        if (this.printerStatus?.status?.[Asset?.ipaddress]?.isConnected) {
          this.printOrderReceipt();
        } else {
          this.printerStatusData.ipAddress = Asset?.ipaddress;
          this.printerStatusData.error =
            this.printerStatus?.status?.[Asset?.ipaddress].event;
          this.confirmPrinterOffline(event, 'printReceipt');
        }
      } else {
        this.printOrderReceipt();
      }
    }
  }
  @LogArgs()
  printOrderReceipt() {
    this.showLoader = true;
    const param = {
      orderDocId: this.currentTableOrderDocId,
    };
    this.kioskOrderService
      .postPrintOrderReceipt(
        this.currentTableOrderDocId,
        param,
        PRINT.T_ORDER_REC
      )
      .subscribe({
        next: (res) => {
          this.selectedTableData['aProperties']['status'] = TABLE_STATUS.RECEIPT_GEN;
          this.showLoader = false;
          this.messageService.add({
            severity: 'success',
            key: 'kiosk-order-page',
            summary: 'Printer',
            detail: res?.['message'] ?? 'Receipt Print Success',
          });
        },
        error: (err) => {
          this.showLoader = false;
          this.messageService.add({
            severity: 'error',
            key: 'kiosk-order-page',
            closable: true,
            summary: 'Printer Error',
            detail: `ORDER ID: ${this.cartOrderId['orderId']} ${err?.error?.msg || err?.msg
              }`,
          });
        },
      });
  }

  @LogArgs()
  cancelKitchenItemsMod() {
    this.isInKitchenItems = false;
    this.selectedInKitchenProdcut = null;
    this.changedInKitchenItems = {
      products: {
        remove: [],
      },
      tableId: '',
      orderDocId: '',
      subOrderId: '',
    };
  }

  @LogArgs()
  editInTableItems(product) {
    if (this.tableCartItems?.length) return;
    this.selectedInKitchenProdcut = product;
    this.isInKitchenItems = true;
    this.changedInKitchenItems = {
      products: {
        remove: [],
      },
      tableId: '',
      orderDocId: '',
      subOrderId: '',
    };
    this.editInKitchenItems = product.items?.map((item) => {
      return {
        ...item,
        originalQty: item.quantity,
      };
    });
  }

  // checkSubOrderQuantity(data) {
  //   return data.every(item => item.quantity === 0) && this.tableCartItems?.length;
  // }

  // TDDO new store add data to new data base with db name and store id
  // TODO check why quantity
  @LogArgs()
  actionOnTableCartItems(product: IProductData, action: string, event?: any) {
    if (action === 'increment') {
      !this.isInKitchenItems && this.incrementCartQty(product);
      this.isInKitchenItems && this.incrementInKitchenItems(product);
    } else if (action === 'decrement') {
      !this.isInKitchenItems && this.decrementCartQty(product);
      this.isInKitchenItems && this.decrementInKitchenItems(product);
    } else if (action === 'add-to-kitchen') {
      if (this.printerStatus.allConnected) {
        this.updateTableCall();
      } else {
        const kotAsset = this.receiptTemplate.assetsInfo.find((e) => e?.isKot);
        if (
          kotAsset &&
          this.printerStatus?.status?.[kotAsset?.ipaddress]?.type === 'kAgent'
        ) {
          if (this.printerStatus?.status?.[kotAsset?.ipaddress]?.isConnected) {
            this.updateTableCall();
          } else {
            this.printerStatusData.ipAddress = kotAsset?.ipaddress;
            this.printerStatusData.error =
              this.printerStatus?.status?.[kotAsset?.ipaddress].event;
            this.confirmPrinterOffline(event, 'addToKitchen');
          }
        } else {
          this.updateTableCall();
        }
      }
    } else if (action === 'remove-from-kitchen') {
      this.removeKitchenItemsCall();
    }
  }

  confirmPrinterOffline(e: Event, key: string) {
    this.popUpKey = key;
    this.confirmationService?.close();
    setTimeout(() => {
      this.confirmationService.confirm({
        target: e.target as EventTarget,
        message: 'Printer is Offline',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptVisible: key != 'printReceipt',
        rejectVisible: key != 'printReceipt',
        key: this.popUpKey,
        accept: () => {
          this.updateTableCall();
        },
        reject: () => {
          console.log('rejected');
        },
      });
    }, 100);
  }

  @LogArgs()
  removeKitchenItemsCall() {
    this.showLoader = true;
    this.kioskOrderService
      .submitTableOrder(
        this.changedInKitchenItems.tableId,
        this.changedInKitchenItems.products.remove,
        this.changedInKitchenItems.orderDocId || this.currentTableOrderDocId,
        this.changedInKitchenItems.subOrderId,
        'remove'
      )
      .subscribe({
        next: (data: any) => {
          if (data) {
            this.selectedInKitchenProdcut = null;
            this.isInKitchenItems = false;
            this.showLoader = false;
            this.tableCartItems = [];
            this.editInKitchenItems = [];
            this.currentTableOrderDocId = data?._id;
            this.subOrderData = data?.subOrderData;
            this.cartOrderId = data;
            // make checkout update call when a new chunck is added into table
            if (this.cartOrderId) {
              this.checkoutCart(false);
            }
            this.store.dispatch(
              KioskActions.updateKitchenSentData({
                kitchenData: this.subOrderData,
              })
            );
            for (let j = 0; j < data.orderDetails?.items?.length; j++) {
              this.store.dispatch(
                KioskActions.updateTableCart({
                  prodId: data.orderDetails.items[j]?._id,
                  isTable: this.showTableModal,
                })
              );
            }
          }
        },
        error: (err) => {
          this.showLoader = false;
          this.messageService.add({
            severity: 'error',
            key: 'kiosk-order-page',
            summary: 'Error',
            detail: err?.toString(),
          });
        },
      });
  }

  @LogArgs()
  manageChangeInKitchenItems(item) {
    let isIdPresent = false;
    const itemsToRemove = this.changedInKitchenItems?.products?.remove?.map(
      (p) => {
        if (p._id === item._id) {
          isIdPresent = true;
          return {
            ...p,
            quantity: item.originalQty - item.quantity,
            all: {
              ...item['all'],
              qty: item.quantity,
            },
          };
        }
        return p;
      }
    );

    if (!isIdPresent) {
      itemsToRemove.push({
        _id: item._id,
        quantity: item.originalQty - item.quantity,
        all: {
          ...item['all'],
          qty: item.quantity,
        },
      });
    }
    let subOrderId;
    this.subOrderData?.forEach(({ kId, _id }) => {
      if (kId === this.selectedInKitchenProdcut?.kId) {
        subOrderId = _id;
      }
    });

    this.changedInKitchenItems = {
      tableId: this.selectedTableData._id,
      orderDocId: this.selectedTableData.aProperties.orderDocId,
      subOrderId,
      products: {
        remove: itemsToRemove,
      },
    };
  }

  @LogArgs()
  incrementInKitchenItems(product: any) {
    if (product?.originalQty === product?.quantity) return;
    this.editInKitchenItems = this.editInKitchenItems?.map((item) => {
      if (item._id === product._id) {
        const modP = {
          ...item,
          quantity: item.quantity + 1,
        };
        this.manageChangeInKitchenItems(modP);
        return modP;
      }
      return item;
    });
  }

  @LogArgs()
  removeInKitchenItem(product) {
    if (!product.quantity) return;
    if (!this.cartOrderId?.payment?.price?.total) {
      this.showTaxBreakup = true;
    }
    const cartProducts = [];
    this.editInKitchenItems?.forEach((item) => {
      if (item._id === product._id) {
        const modP = {
          ...item,
          quantity: 0,
        };
        this.manageChangeInKitchenItems(modP);
        cartProducts.push(modP);
      } else {
        cartProducts.push(item);
      }
    });
    this.editInKitchenItems = cartProducts;
  }

  @LogArgs()
  decrementInKitchenItems(product) {
    if (!product.quantity) return;
    const cartProducts = [];
    this.editInKitchenItems.forEach((item) => {
      if (item._id === product._id) {
        const modP = {
          ...item,
          quantity: item.quantity - 1,
        };
        this.manageChangeInKitchenItems(modP);
        cartProducts.push(modP);
      } else {
        cartProducts.push(item);
      }
    });
    this.editInKitchenItems = cartProducts;
  }

  //   table modal pagination method
  @LogArgs()
  salesModalPaginate(event: PaginatorState) {
    this.salesModal.pagination.noOfPagesList = event.pageCount;
    if (event.rows) {
      this.salesModal.pagination.recordsPerPage = event.rows;
    }
    this.salesModal.appLoader = true;
    let recordsPerPage;
    let skip;
    this.salesModal.pagination.pageNumber = event.page + 1;
    if (event.page === 0) {
      skip = 0;
      recordsPerPage = this.salesModal.pagination.recordsPerPage;
    } else {
      skip = event.page * this.salesModal.pagination.recordsPerPage;
      recordsPerPage = this.salesModal.pagination.recordsPerPage;
    }
    this.downloadProductSalesReport(
      true,
      false,
      skip,
      recordsPerPage,
      this.salesModal.shiftTimeRange
    );
  }

  @LogArgs()
  downloadProductSalesReport(
    view = true,
    isCount = true,
    skip?: number,
    limit?: number,
    timeRange?: {
      start: any;
      end: any;
    }
  ) {
    this.showLoader = true;
    this.salesModal.appLoader = true;
    const startDate = timeRange?.start || moment().startOf('days').valueOf();
    const endDate = timeRange?.end || moment().endOf('days').valueOf();
    let locationIds = '';
    locationIds = this.salesModal.maestroStoreId;
    if (view) {
      this.salesModal.appLoader = true;
      this.salesModal.displayModal = true;
      this.analyticsService
        .getProductSalesByStore(
          startDate,
          endDate,
          'json',
          locationIds,
          isCount,
          skip ?? 0,
          limit ?? this.salesModal.pagination.recordsPerPage,
          'iotzen-pos',
          this.reportGenFromServer()
        )
        .subscribe({
          next: (data) => {
            if (data?.['count'] > 0) {
              this.salesModal.pagination.totalNoOfRecords = data?.['count'];
              this.analyticsService.getProductSalesByStore(
                startDate,
                endDate,
                'json',
                locationIds,
                false,
                skip ?? 0,
                limit ?? this.salesModal.pagination.recordsPerPage,
                'iotzen-pos',
                this.reportGenFromServer()
              ).subscribe({
                next: (data) => {
                  this.salesModal.displayModal = true;
                  this.salesModal.tableApiData = data;
                  this.salesModal.tabledata = data?.['data']
                    ? data?.['data']
                    : [];
                  this.showLoader = false;
                  this.salesModal.appLoader = false;
                  if (data?.['showAmount']) {
                    this.salesModal.header = `Total Sales: ${data?.['total']?.['amount']}`;
                  }

                  const cols = [];
                  if (this.salesModal.tableApiData?.['headers']) {
                    for (let i = 0; i < this.salesModal.tableApiData['headers'].length; i++) {
                      const header = this.salesModal.tableApiData['headers'][i];
                      const col = {};
                      col['field'] = header.header ? header.header : null;
                      col['key'] = header.key ? header.key : null;
                      col['type'] = header.type ? header.type : null;
                      cols.push(col);
                    }
                    this.salesModalTableData = cols;
                  }
                },
                error: (err) => {
                  this.messageService.add({
                    severity: 'error',
                    key: 'kiosk-order-page',
                    summary: 'Error',
                    detail: err?.message ??
                      `Failed to get Sales Report, try again ${this.reportGenFromServer()}? 'server' : 'local'`,
                  });
                },
              });
            } else if (data?.['data']) {
              this.salesModal.displayModal = true;
              this.salesModal.tableApiData = data;
              this.salesModal.tabledata = data?.['data'] ? data?.['data'] : [];
              this.showLoader = false;
              this.salesModal.appLoader = false;
            }

            this.showLoader = false;
            this.salesModal.appLoader = false;
          },
          error: (err) => {
            this.showLoader = false;
            this.salesModal.appLoader = false;
            this.messageService.add({
              severity: 'error',
              key: 'kiosk-order-page',
              summary: 'Error',
              detail: err?.message ??
                `Failed to get Sales Report, try again ${this.reportGenFromServer() ? 'server' : 'local'
                }`,
            });
          },
          complete: () => {
            this.rxdb.updateTelemetry().then(console.log).catch(console.error);
          }
        });
    }
  }

  @LogArgs()
  getTableColumnheader() {
    const cols = [];
    if (this.salesModal.tableApiData?.['headers']) {
      this.salesModal.tableApiData['headers'].map((header) => {
        const col = {};
        col['field'] = header.header ? header.header : null;
        col['key'] = header.key ? header.key : null;
        col['type'] = header.type ? header.type : null;
        cols.push(col);
      });
    }
  }

  @LogArgs()
  resetModalData() {
    this.salesModal.header = '';
    this.salesModal.tableApiData = {};
    this.salesModal.tabledata = [];
    this.salesModal.pagination.totalNoOfRecords = 0;
    this.salesModal.displayConfirmModal = false;
    this.salesModal.shiftTimeRange = undefined;
    // this.reportGenFromServer.set(false);
  }

  @LogArgs()
  buildExpectedDeliveryDateTime(date) {
    let dateTime: any = '';
    if (date) {
      dateTime = this.dateFormatter.convertDateToSelectedTimezone(
        date,
        'DD/MM/YY'
      );
    }
    if (date) {
      dateTime =
        dateTime +
        ', ' +
        this.dateFormatter.getFormattedTimeSlotForSingleTime(date);
    }
    return dateTime;
  }

  @LogArgs()
  isAnyItemInKitchen(data) {
    this.isKitchenEmpty = true;
    if (data?.length > 0) {
      for (const subOrder of data) {
        for (const item of subOrder.items) {
          if (item.quantity !== 0) {
            this.isKitchenEmpty = false;
            break;
          }
        }
        if (!this.isKitchenEmpty) {
          break;
        }
      }
    }
    return this.isKitchenEmpty;
  }

  @LogArgs()
  printSalesReport(isGroupByDinetype = false) {
    const startDate =
      this.salesModal?.shiftTimeRange?.start ??
      moment().startOf('days').valueOf();
    const endDate =
      this.salesModal?.shiftTimeRange?.end ?? moment().endOf('days').valueOf();
    const printType = 'print';
    this.analyticsService
      .printSalesReports(
        startDate,
        endDate,
        printType,
        isGroupByDinetype,
        this.reportGenFromServer()
      )
      .subscribe({
        next: (res: IServerSalesReportResponse) => {
          this.printer.printReceiptByContent(res?.content, res?.assetDetails);
          this.messageService.add({
            severity: 'success',
            key: 'kiosk-order-page',
            summary: 'Printer',
            detail: res['msg'] ?? 'Sales Report Printed',
          });
        },
        error: (error: any) => {
          console.log(error);
          this.messageService.add({
            severity: 'error',
            key: 'kiosk-order-page',
            summary: 'Something Went Wrong!',
            detail: error.toString(),
          });
        },
      });
  }

  @LogArgs()
  checkPasswordToOpenSales() {
    const pin = this.maestroInfoData?.posData?.salesReportPassword?.value;
    if (pin && this.salesModal?.password === pin?.toString()) {
      if (!this.isInternetAvailable) {
        this.reportGenFromServer.set(false);
      }
      this.salesModal.selectedSalesOption = 'shiftBase';
      this.salesModal.displayConfirmModal = false;
      this.salesModal.password = null;
      this.salesModal.showShiftListModal = true;
      this.store.dispatch(
        KioskActions.getUserAllShift({
          startDate: new Date(this.startRangeDate).getTime(),
          endDate: new Date(this.endRangeDate).getTime(),
        })
      );
    } else {
      this.salesModal.password = null;
      this.messageService.add({
        severity: 'error',
        key: 'kiosk-order-page',
        summary: 'Entered wrong password',
        detail: 'Please enter correct password',
      });
    }
  }

  @LogArgs()
  openSalesModal() {
    this.maxSalesDetailsDate = new Date();
    this.minSalesDetailsDate = new Date(Date.now() - 24 * 60 * 60 * 1000);
    this.startRangeDate = this.minSalesDetailsDate;
    this.endRangeDate = this.maxSalesDetailsDate;
    this.salesModal.displayConfirmModal = true;
    !this.selectedDataRange.start && this.onSalesDateSelected('start');
    !this.selectedDataRange.end && this.onSalesDateSelected('end');
    if (this.pinKeyboardComp) {
      this.pinKeyboardComp.reportPinInputFocused();
    }
  }

  @LogArgs()
  onSalesDateSelected(type: string) {
    const date =
      type === 'start'
        ? this.startRangeDate
        : type === 'end'
          ? this.endRangeDate
          : null;
    const dateVal = this.dateFormatter.getDateComponents(date);
    const timeVal = this.dateFormatter.getFormattedTimeSlotForSingleTime(
      new Date(date)
    );
    const dateStr = dateVal.day + ' ' + dateVal.month + ' ' + timeVal;

    this.selectedDataRange = {
      start: type === 'start' ? dateStr : this.selectedDataRange.start,
      end: type === 'end' ? dateStr : this.selectedDataRange.end,
    };
  }

  @LogArgs()
  confirmLogOut(event: Event, key: string) {
    this.confirmationService?.close();
    let mess = this.translatePipe.transform('app.pos.logoutConfirm');
    if (!this.isInternetAvailable) {
      mess = this.translatePipe.transform('app.pos.logoutConfirm');
    } else if (this.shift.isStarted) {
      mess = this.translatePipe.transform('app.pos.logoutCofirmIfShiftActive');
    }
    // const username = 'username : Gaurav'
    // const shiftTime = 'Shift start time and duration'+ this.shift?.fromTime;
    this.popUpKey = key;

    setTimeout(() => {
      this.confirmationService.confirm({
        target: event.target as EventTarget,
        //  message: `${mess}\n${username}\n${shiftTime}`,
        message: mess,
        key: key,
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: this.shift.isStarted
          ? this.translatePipe.transform('app.pos.endShiftAndLogout')
          : 'No',
        rejectLabel: this.shift.isStarted
          ? this.translatePipe.transform('app.pos.logoutButton')
          : 'Yes',
        acceptVisible: !!this.isInternetAvailable,
        rejectVisible: !!this.isInternetAvailable,
        accept: () => {
          if (this.shift.isStarted) {
            this.store.dispatch(KioskActions.endUserShift());
            setTimeout(() => {
              this.logout();
            }, 1000);
          }
        },
        reject: () => {
          this.logout();
        },
      });
    }, 200);
  }

  @LogArgs()
  confirmShiftStart(key: string) {
    this.confirmationService?.close();
    this.updateInProgreesShitTime(this.userShiftProgress);
    this.popUpKey = key;
    const target = event.target;

    setTimeout(() => {
      this.confirmationService.confirm({
        target: target as EventTarget,
        message: this.shift.isStarted
          ? this.translatePipe.transform('app.pos.endYourShiftConfirm')
          : this.translatePipe.transform('app.pos.startYourShiftConfirm'),
        icon: 'pi pi-exclamation-triangle',
        key: key,
        accept: () => {
          this.shift.isStarted = !this.shift.isStarted;
          if (!this.shift.isStarted) {
            this.store.dispatch(KioskActions.endUserShift());
            this.messageService.add({
              severity: 'warn',
              key: 'kiosk-order-page',
              summary: 'Shift Ended',
              detail: 'Your shift ended successfully..!!',
            });
          } else {
            this.store.dispatch(KioskActions.addUserShift());
            this.messageService.add({
              severity: 'success',
              key: 'kiosk-order-page',
              summary: 'Shift Started',
              detail: 'Your shift started successfully..!!',
            });
          }
        },
        reject: () => { },
      });
    }, 200);
  }

  @LogArgs()
  logout() {
    this.app.logout().then((r) => r);
  }

  @LogArgs()
  resetPosStoredData() {
    window.caches.keys().then(function (names) {
      for (const name of names) {
        window.caches
          .delete(name)
          .then((r) => r)
          .catch(console.error);
      }
    });

    window.indexedDB.databases().then((dbs) => {
      dbs.forEach((db) => {
        window.indexedDB.deleteDatabase(db.name);
      });
    });

    this.logout();
  }

  @LogArgs()
  toggleSearchBar(hide = false) {
    this.clearTimeout();
    if (hide) {
      this.searchedProduct = '';
      this.openSearchBar = false;
    } else {
      this.timeOut = setTimeout(() => {
        this.openSearchBar = !!this.searchedProduct?.trim().length;
      }, 200);
    }
  }

  @LogArgs()
  clearTimeout() {
    this.timeOut && clearTimeout(this.timeOut);
  }

  @LogArgs()
  handleAvailableDineTypes() {
    const count =
      (this.dineTypeSelectBtn?.length || 0) +
      (this.additionalDineTypes?.length || 0);
    this.showWallpaper = count !== 1;
    if (!this.showWallpaper) {
      const dineType =
        this.dineTypeSelectBtn?.[0] || this.additionalDineTypes?.[0];
      this.dineTypeSelectBtn?.[0] && this.setDineType(dineType.value);
      this.additionalDineTypes?.[0] &&
        this.setDineType(dineType.value, dineType);
    }
  }

  @LogArgs()
  discardCartOrder() {
    this.currentOrderPaid = false;
    this.closeAddOrderModal();
    this.handleAvailableDineTypes();
    this.isReloadRequiredMemo();
    this.updateDineTypeSelectBtn(null, '');
  }

  @LogArgs()
  addItemBtnDisableCheck(disabled: string) {
    if (disabled && disabled === 'isInKitchenItem') {
      this.messageService.clear();
      if (
        this.isInKitchenItems &&
        this.SelectBtnValue === DINETYPE.TABLE &&
        this.isAvailableTableNotSelected &&
        !this.currentTableOrderDocId
      ) {
        this.messageService.add({
          severity: 'warn',
          key: 'kiosk-order-page',
          summary: 'Warn',
          detail: 'Please select table to add new items',
        });
      } else if (this.isInKitchenItems && this.currentTableOrderDocId) {
        this.messageService.add({
          severity: 'warn',
          key: 'kiosk-order-page',
          summary: 'Warn',
          detail: 'Please update the kitchen items to add new items',
        });
      }
    } else if (disabled && disabled === 'noAccessToAdd') {
      this.messageService.add({
        severity: 'warn',
        key: 'kiosk-order-page',
        summary: 'Warn',
        detail: 'You do not have access to add products after receipt generation',
      });
    }
  }

  @LogArgs()
  disableEnableDineTypeBtn(action) {
    if (action === 'enable') {
      this.dineTypeSelectBtn = this.dineTypeSelectBtn?.map((dineType) => {
        dineType.disabled = false;
        return dineType;
      });
    } else if (action === 'disable') {
      this.dineTypeSelectBtn = this.dineTypeSelectBtn?.map((dineType) => {
        if (dineType?.value !== this.SelectBtnValue) dineType.disabled = true;
        return dineType;
      });
    }
  }

  @LogArgs()
  checkIfTableSelected() {
    if (
      this.SelectBtnValue === DINETYPE.TABLE &&
      this.isAvailableTableNotSelected &&
      !this.currentTableOrderDocId
    ) {
      this.isInKitchenItems = true;
      if (!this.selectedTableData || this.isAvailableTableNotSelected) {
        this.resetCartValues();
      }
    } else {
      this.isInKitchenItems = !(
        this.currentTableOrderDocId || !this.isAvailableTableNotSelected
      );
    }
    if (
      this.showSelectedTableData &&
      !Object.keys(this.showSelectedTableData).length
    ) {
      this.isInKitchenItems = true;
      this.isAvailableTableNotSelected = true;
    }
  }

  @LogArgs()
  getSelectedShiftSales(shift) {
    const startDate = moment(shift?.startDate).valueOf();
    const endDate = shift?.endDate
      ? moment(shift.endDate).valueOf()
      : moment().valueOf();
    const timeRange = {
      start: moment(startDate).valueOf(),
      end: moment(endDate).valueOf(),
    };
    this.salesModal.shiftTimeRange = timeRange;
    this.startRangeDate = new Date(startDate);
    this.endRangeDate = new Date(endDate);
    this.downloadProductSalesReport(true, true, null, null, timeRange);
  }

  @LogArgs()
  getSelectedTimeBaseSales(startDate, endDate) {
    const timeRange = {
      start: moment(startDate).valueOf(),
      end: endDate ? moment(endDate).valueOf() : moment().valueOf(),
    };
    this.salesModal.shiftTimeRange = timeRange;
    this.downloadProductSalesReport(true, true, null, null, timeRange);
  }

  @LogArgs()
  salesOptionsChange() {
    if (this.salesModal.selectedSalesOption === 'timeBase') {
      this.maxSalesDetailsDate = new Date();
      this.minSalesDetailsDate = new Date(
        new Date().getTime() - 24 * 60 * 60 * 1000
      );
      this.startRangeDate = this.minSalesDetailsDate;
      this.endRangeDate = this.maxSalesDetailsDate;
    }
  }

  // @LogArgs()
  getDuration(startDate: any, endDate: any) {
    if (endDate)
      return this.dateFormatter.getTimeDifferenceFromString(
        endDate,
        startDate,
        true
      );
    else return '-';
  }

  @LogArgs()
  updateDbUrl() {
    if (this.syncdb) {
      this.store.dispatch(
        KioskActions.updateSyncDbUrl({
          url: this.syncdb.url,
          userId: this.syncdb.userid,
          userPassword: this.syncdb.password,
        })
      );
    }
  }

  @LogArgs()
  updateInProgreesShitTime(shiftStartDate) {
    const currentTime = new Date().toISOString();
    const createdTime = shiftStartDate
      ? new Date(shiftStartDate).toISOString()
      : currentTime;
    const { timePassed, endTime } =
      this.dateFormatter.getTimeDifferenceFromString(currentTime, createdTime);
    this.shift.startTimeFormatted = `${endTime}m (${timePassed})`;
  }

  @LogArgs()
  confirmPrinterAndInternetService(event, key) {
    this.confirmationService?.close();
    this.popUpKey = key;
    let message = '';

    switch (key) {
      case 'printerStatus':
        message = this.translatePipe.transform('app.pos.printerStatus');
        break;
      case 'internetStatus':
        message = this.translatePipe.transform('app.pos.internetStatus');
        break;
      case 'syncStatus':
        message = this.translatePipe.transform('app.pos.syncStatus');
        break;
    }

    setTimeout(() => {
      this.confirmationService.confirm({
        target: event.target as EventTarget,
        message: message,
        // icon: 'pi pi-exclamation-triangle'
        acceptVisible: false,
        rejectVisible: false,
        key: this.popUpKey,
      });
    }, 200);
  }

  toggleChipSelection(chip: any): void {
    chip.selected = !chip.selected;
  }

  setOrderInstruction(item) {
    this.showAddInstructuinDialog = true;
    this.chips?.forEach((chip) => {
      chip.selected = item?.defaultInstruction?.includes(chip?.label);
    });
    this.currentAddOrderInstructionObj = item;
  }

  addOrderInstruction(e) {
    const selectedChips = this.chips?.filter((chip) => chip?.selected);
    const selectedChipsLabel = selectedChips?.map((chip) => chip?.label);
    const matchingObject = this.tableCartItems?.find(
      (obj) =>
        obj?.productInfo?._id ===
        this.currentAddOrderInstructionObj?.productInfo?._id
    );
    if (matchingObject) {
      matchingObject.customInstruction = e?.trim();
      matchingObject.defaultInstruction = selectedChipsLabel;
    }
    this.chips?.forEach((chip) => (chip.selected = false));
    this.showAddInstructuinDialog = false;
  }

  getPinPassword(e) {
    this.salesModal.password = e;
  }

  onLangChange() {
    this.langUtilService.updateUserLanguage(this.selectedLang);
  }

  protected readonly TABLE_STATUS = TABLE_STATUS;
  protected readonly window = window;
  protected readonly document = document;
}
